@import 'normalize';
@import 'variables';
@import 'breaker';
@import 'mixins';
@import 'shared/helpers';
@import 'shared/fonts';

// Pages
@import 'pages/contact-page';
@import 'pages/employees';
@import 'pages/employees-single';
@import 'pages/page';

// Components
@import 'components/site-navigation';
@import 'components/site-menu';
@import 'components/hamburger';
@import 'components/big-page-header';
@import 'components/page-header';
@import 'components/big-text-block';
@import 'components/link-block';
@import 'components/address-block';
@import 'components/information-banner';
@import 'components/image-and-text';
@import 'components/job-ads';
@import 'components/site-footer';
@import 'components/sidebar-menu';
@import 'components/text-block';
@import 'components/image-block';
@import 'components/contact-block';
@import 'components/law-area-select';
@import 'components/accordion';

html, body {
    font-family: 'Maison Neue';
    position: relative;
}

body {
    // background: #111114;
    // animation: animateBody 1s ease-in-out forwards;
}

@keyframes animateBody {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
