.address-block {
    width: 100%;
    padding: 150px 20px;
    background-color: $beige;

    @include below(620px) {
        padding: 65px 20px 95px 20px;
    }

    &__inner {
        width: 100%;
        max-width: $contentWidth;
        margin: 0 auto;
        display: flex;
        flex-direction: row;

        @include below(620px) {
            flex-direction: column;
        }
    }

    &__column {
        width: 50%;
        padding: 0 20px 0 0;

        @include below(620px) {
            width: 100%;
            max-width: none;
            padding: 30px 0 0 0;
        }

        &-title {
            font-size: 16px;
            line-height: 24px;
            color: #000;
            font-weight: 500;
            margin: 0 0 10px 0;
        }

        &-text {

            p {
                font-size: 24px;
                line-height: 36px;
                color: #000;
                font-weight: 400;

                @include below(620px) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
    }
}
