.site-menu {
    width: 100%;
    max-height: 0px;
    background: #493D37;
    transition: max-height .3s ease-in-out, padding .3s ease-in-out;
    overflow: hidden;
    pointer-events: none;
    padding-right: 20px;
    padding-left: 20px;

    &.open {
        max-height: 700px;
        pointer-events: all;
        padding-top: 60px;
        padding-bottom: 60px;

        .site-menu__inner {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &__inner {
        width: 100%;
        max-width: 1140px;
        padding: 0 20px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        opacity: 0;
        transform: translateY(5px);
        transition: opacity .2s ease-in-out, transform .2s ease-in-out;
        transition-delay: .2s;

        @include below(760px) {
            justify-content: flex-end;
        }
    }

    &__title {
        font-size: 48px;
        line-height: 60px;
        font-weight: 400;
        color: #fff;

        @include below(960px) {
            font-size: 38px;
        }

        @include below(760px) {
            display: none;
        }
    }

    &__head-menu {
        display: none;

        @include below(960px) {
            display: block;
        }

        .menu {
            text-align: right;

            li {
                list-style: none;

                @include below(960px) {
                    margin-bottom: 10px;
                }

                a {
                    color: #fff;
                    display: block;
                    text-decoration: none;
                    font-size: 24px;
                    line-height: 36px;
                }
            }
        }
    }

    &__law-menu-button {
        color: #fff;
        font-size: 24px;
        line-height: 36px;
        cursor: pointer;
        margin-top: 35px;
        text-align: right;
        display: none;

        @include below(960px) {
            display: block;
        }

        &:after {
            content: "";
            display: inline-block;
            width: 14px;
            height: 7px;
            vertical-align: middle;
            margin-left: 24px;
            background-image: url("../img/white-arrow-down.svg");
            background-repeat: no-repeat;
            background-size: contain;
            will-change: transform;
            transition: transform .1s linear;
        }
    }

    &__law-menu {
        @include below(960px) {
            max-height: 0px;
            transition: max-height .3s linear;
            overflow: hidden;
            pointer-events: none;
            margin-top: 10px;

            &.open {
                max-height: 300px;
                pointer-events: all;
                overflow: visible;
            }
        }

        .menu {
            text-align: right;

            &:hover {
                li a {
                    opacity: 0.6;

                    &:hover {
                        opacity: 1
                    }
                }
            }

            li {
                list-style: none;

                a {
                    color: #fff;
                    opacity: 1;
                    display: inline-block;
                    text-decoration: none;
                    font-size: 16px;
                    line-height: 34px;
                    transition: opacity .2s ease-in-out;
                    position: relative;

                    &:hover {
                        opacity: 1;

                        // &:after {
                        //     opacity: 1;
                        //     transform: translateY(0);
                        // }
                    }

                    // &:after {
                    //     content: "";
                    //     width: 100%;
                    //     height: 1px;
                    //     position: absolute;
                    //     background: #fff;
                    //     opacity: 0;
                    //     bottom: 3px;
                    //     left: 0;
                    //     transform: translateY(4px);
                    //     transition: opacity .2s ease-in-out, transform .2s ease-in-out;
                    // }
                }
            }
        }
    }
}
