.employees {
    width: 100%;
    padding: 105px 0 150px 0;
    background: $beige;

    @include below(620px) {
        padding: 50px 0;
    }

    &__header {
        width: 100%;
        max-width: 1140px;
        margin: 0 auto 70px auto;
        display: flex;
        padding: 0 20px;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        @include below(620px) {
            flex-direction: column;
            margin-bottom: 30px;
        }

        &-title {
            font-size: 24px;
            line-height: 36px;
            font-weight: 400;

            @include below(620px) {
                font-size: 20px;
                line-height: 26px;
                margin: 0 0 25px 0;
            }
        }
    }

    &__grid {
        width: 100%;
        max-width: 1140px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 0 5px;
        position: relative;
    }
}

.employee {
    &__card {
        width: calc(100% / 4);
        max-width: calc(100% / 4);
        padding: 0 15px 60px 15px;
        text-decoration: none;
        color: #000;
        transition: all .3s ease-in-out;

        &:hover {
            .employee__image .overlay {
                opacity: 1;
            }

            .overlay__text {
                top: 50%;
                opacity: 1;
            }

            // .employee__image:after {
            //     top: 50%;
            //     opacity: 1;
            // }
        }

        &.animate-out {
            // transform: translateY(-10px);
            opacity: 0;
        }

        &.hide {
            display: none;

            &.no-filter {
                display: block !important;
            }
        }

        @include below(768px) {
            width: calc(100% / 3);
            max-width: calc(100% / 3);
        }

        @include below(620px) {
            width: calc(100% / 2);
            max-width: calc(100% / 2);
        }

        @include below(420px) {
            width: 100%;
            max-width: 100%;
            padding-bottom: 40px;
        }
    }

    &__image {
        width: 100%;
        display: block;
        margin-bottom: 33px;
        position: relative;

        &:after {
            // content: "Kontakt & info";
            // display: block;
            // width: 100%;
            // color: #fff;
            // text-align: center;
            // z-index: 10;
            // font-size: 20px;
            // line-height: 22px;
            // font-weight: 400;
            // opacity: 0;
            // @include vertical-align(absolute);
            // top: 52%;
            // transition: opacity .3s ease-in-out, top .3s ease-in-out;
        }

        .overlay {
            opacity: 0;
            z-index: 9;
            background-color: rgba(#000, 0.35);

            &__text {
                display: block;
                width: 100%;
                color: #fff;
                text-align: center;
                z-index: 10;
                font-size: 20px;
                line-height: 22px;
                font-weight: 400;
                opacity: 0;
                @include vertical-align(absolute);
                top: 52%;
                transition: opacity .3s ease-in-out, top .3s ease-in-out;
            }
        }

        img {
            max-width: 100%;
            display: block;
        }
    }

    &__name {
        font-size: 20px;
        line-height: 22px;
        font-weight: 400;
        margin: 0 0 15px 0;
    }

    &__jobtitle {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
    }
}

.employee__filter-chosen {
    cursor: pointer;
    position: relative;

    @include below(620px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &:after {
        content: "";
        display: inline-block;
        width: 14px;
        height: 7px;
        vertical-align: middle;
        margin-left: 24px;
        background-image: url("../img/black-arrow-down.svg");
        background-repeat: no-repeat;
        background-size: contain;
        will-change: transform;
        transition: transform .2s linear;
    }

    &.open {
        &:after {
            transform: rotate(180deg);
        }
    }
}

.employee__filter {
    width: 100%;
    max-width: 350px;
    font-size: 24px;
    line-height: 46px;
    font-weight: 400;
    text-align: right;

    @include below(620px) {
        font-size: 16px;
        line-height: 36px;
        text-align: left;
        padding: 15px 30px;
        border: 1px solid #000;
        border-radius: 5px;
        max-width: none;
    }

    &-list {
        text-align: right;
        overflow: hidden;
        height: 0;
        transition: height .3s ease-in-out;

        @include below(620px) {
            text-align: left;
            padding: 0 20px;
        }

        &-item {
            list-style: none;
            cursor: pointer;
            opacity: 0.5;
            position: relative;
            transition: opacity .2s ease-in-out;

            &:hover {
                opacity: 1;
            }

            &.active {
                opacity: 1;

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: "";
                display: inline-block;
                color: #000;
                position: absolute;
                width: 6px;
                right: -20px;
                height: 6px;
                background: #000;
                border-radius: 100%;
                top: 50%;
                opacity: 0;
                transform: translateY(-50%);

                @include below(620px) {
                    left: -20px;
                }
            }
        }
    }
}

.static-card {
    // .employee__image {
    //     &:after {
    //         content: "Karriär";
    //     }
    // }
}
