// Fonts
.title-xxl {
    font-size: 84px;
    line-height: 94px;
}

strong, b {
    font-weight: 500 !important;
}

.center {
    margin: 0 auto;
}

.overlay {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: rgba(#000, 0.3);
    transition: background-color .3s ease-in-out, opacity .3s ease-in-out;
}

.row {
    width: 100%;
    padding: 0 20px;
}

.site-wrapper {
    width: 100%;
    position: relative;
    background: #fff;
}

p {
    font-size: 16px;
    line-height: 29px;
    font-weight: 400;
}

.underline-hover {
    display: block;
    position: relative;

    &:hover {
            &:after {
                opacity: 1;
                transform: translateY(0);
            }
    }

    &:after {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        background: #000;
        opacity: 0;
        transform: translateY(4px);
        transition: opacity .2s ease-in-out, transform .2s ease-in-out;
    }
}

.background-logo {
    position: absolute;
    z-index: -2;
    top: 150px;
    width: 100%;
    text-align: center;
}


// .page-transition {
//     opacity: 0;
//     // transform: translateY(30px);
//     animation: fadepage 1.5s ease-in-out forwards;
//     backface-visibility: hidden;
//     // animation-delay: 0.5s;
//
//     // &__out {
//     //     animation: fadepageout .5s ease-in-out;
//     // }
// }
//
// @keyframes fadepage {
//     0% {
//       opacity: 0;
//       // transform: translateY(30px);
//     }
//
//     50% {
//       opacity: 1;
//       // transform: translateY(0);
//     }
//
//     75% {
//       opacity: 1;
//       // transform: translateY(0);
//     }
//
//     100% {
//       opacity: 1;
//       // transform: translateY(0);
//     }
// }
//
// @keyframes fadepageout {
//   0% {
//     opacity: 1;
//     transform: translateY(0);
//   }
//
//   50% {
//     opacity: 1;
//     transform: translateY(0);
//   }
//
//   100% {
//     opacity: 0;
//     transform: translateY(30px);
//   }
// }
