.sidebar-menu {
    @include below(768px) {
        font-size: 16px;
        line-height: 36px;
        text-align: left;
        border: 1px solid #000;
        border-radius: 5px;
        max-width: none;
        margin: 0 0 50px 0;
        padding: 0 0 15px 0;
    }

    &__current {
        width: 100%;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        display: none;

        @include below(768px) {
            display: flex;
            padding: 15px 30px 0 30px;
        }

        &:after {
            content: "";
            display: inline-block;
            width: 14px;
            height: 7px;
            vertical-align: middle;
            background-image: url("../img/black-arrow-down.svg");
            background-repeat: no-repeat;
            background-size: contain;
            will-change: transform;
            transition: transform .2s linear;
        }

        &.open {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    &__menu {
        @include below(768px) {
            padding: 0 30px;
            overflow: hidden;
            height: 0;
            transition: height .3s ease-in-out;
        }

        li {
            list-style: none;
            cursor: pointer;
            opacity: 0.4;
            position: relative;
            margin-bottom: 15px;
            transition: opacity .2s ease-in-out;

            @include below(768px) {
                margin-bottom: 0;
            }

            a {
                color: #000;
                text-decoration: none;
                font-size: 16px;
                font-weight: 400;
            }

            &:hover {
                opacity: 1;
            }

            &.current_page_item {
                opacity: 1;

                @include below(768px) {
                    display: none;
                }

                &:before {
                    opacity: 1;
                }
            }

            &:before {
                content: "";
                display: inline-block;
                color: #000;
                position: absolute;
                width: 6px;
                left: -20px;
                height: 6px;
                background: #000;
                border-radius: 100%;
                top: 50%;
                opacity: 0;
                transform: translateY(-50%);

                @include below(768px) {
                    display: none;
                }
            }
        }
    }

}
