.big-page-header {
    width: 100%;
    height: 835px;
    max-height: 95vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    position: relative;
    display: flex;
    align-items: center;

    @include below(620px) {
        height: 635px;
    }

    &__inner {
        width: 100%;
        margin: 0 auto;
        z-index: 10;
        padding: 0 20px;
        max-width: $contentWidth;
    }

    &__title {
        width: 100%;
        color: #fff;
        max-width: 720px;
        opacity: 0;
        transform: translateY(10px);
        animation: fadeTitle .5s ease-in-out forwards;
        animation-delay: .2s;

        @include below(960px) {
            font-size: 74px;
            line-height: 84px;
        }

        @include below(620px) {
            font-size: 46px;
            line-height: 56px;
            text-align: center;
        }
    }
}
