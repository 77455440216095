.image-text {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 90px 20px 70px 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @include below(768px) {
        flex-direction: column;
        padding: 45px 20px;
    }

    &__column {
        width: 50%;

        @include below(768px) {
            width: 100%;
        }

        &:first-of-type {
            max-width: 545px;
            padding-right: 30px;

            @include below(768px) {
                max-width: none;
                padding-right: 0;
            }
        }

        &-text {
            @include below(768px) {
                order: 2;
            }
        }

        &-image {
            max-width: 451px;

            .image-text__title {
                display: none;

                @include below(768px) {
                    display: block;
                }
            }

            @include below(768px) {
                max-width: none;
                order: 1;
                margin: 0 0 36px 0;
            }

            img {
                max-width: 100%;
                display: block;
            }
        }
    }

    &__title {
        font-size: 58px;
        line-height: 58px;
        font-weight: 400;
        margin: 0 0 60px 0;

        @include below(768px) {
            display: none;
            margin: 0 0 36px 0;
        }

        @include below(500px) {
            font-size: 32px;
            line-height: 42px;
        }
    }

    &__text {
        h2, h3 {
            font-weight: 500;
        }

        p {
            margin: 20px 0;

            a {
                color: $blackish;
                text-decoration: underline;
            }
        }

        a {
            color: $blackish;
            text-decoration: underline;
        }
    }
}
