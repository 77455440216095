.site-footer {
    width: 100%;
    background: #493d37;
    color: #fff;

    &__header {
        width: 100%;
        width: 100%;
        max-width: 1140px;
        margin: 0 auto;
        padding: 160px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @include below(768px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 60px 20px;
        }
    }

    &__footer {
        width: 100%;
        background: #514540;
        padding: 35px 0;

        &-inner {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            max-width: 1140px;
            padding: 0 20px;
            margin: 0 auto;

            @include below(620px) {
                flex-direction: column;
                justify-content: flex-start;
            }
        }
        &-col {
            width: 50%;
            max-width: 50%;
            color: #b5aea8;

            @include below(620px) {
                width: 100%;
                max-width: none;
            }

            // &:last-of-type {
            //     text-align: right;

            //     @include below(620px) {
            //         text-align: left;
            //         margin-top: 35px;
            //     }
            // }
        }

        a {
            color: #b5aea8;
            text-decoration: none;
            display: inline-block;
            transition: color 0.2s ease-in-out;

            &:hover {
                color: #fff;
            }

            &:after {
                content: "";
                width: 100%;
                height: 1px;
                background: #b5aea8;
                display: block;
                margin-top: 2px;
            }
        }
    }

    &__col {
        width: calc(100% / 3);
        max-width: 356px;

        @include below(768px) {
            width: 100%;
            max-width: none;
        }

        &:first-of-type {
            @include below(768px) {
                padding-bottom: 70px;
                margin-bottom: 70px;
                border-bottom: 2px solid #fff;
            }
        }

        .menu {
            display: inline-block;

            &:hover {
                li a {
                    opacity: 0.6;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            li {
                list-style: none;
                margin-bottom: 10px;

                a {
                    color: #fff;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 24px;
                    line-height: 36px;
                    font-weight: 400;
                    position: relative;
                    transition: opacity 0.2s ease-in-out;

                    // &:hover {
                    //     &:after {
                    //         opacity: 1;
                    //         transform: translateY(0);
                    //     }
                    // }
                    //
                    // &:after {
                    //     content: "";
                    //     width: 100%;
                    //     height: 1px;
                    //     position: absolute;
                    //     background: #fff;
                    //     opacity: 0;
                    //     bottom: 0;
                    //     left: 0;
                    //     transform: translateY(4px);
                    //     transition: opacity .2s ease-in-out, transform .2s ease-in-out;
                    // }
                }
            }
        }
    }

    &__law-menu {
        @include below(768px) {
            display: none;
        }

        .menu {
            display: inline-block;

            &:hover {
                li a {
                    opacity: 0.6;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            li {
                margin-bottom: 0;

                a {
                    font-size: 16px;
                    line-height: 36px;
                    position: relative;
                    display: inline-block;
                    transition: opacity 0.2s ease-in-out;

                    // &:hover {
                    //     &:after {
                    //         opacity: 1;
                    //         transform: translateY(0);
                    //     }
                    // }
                    //
                    // &:after {
                    //     content: "";
                    //     width: 100%;
                    //     height: 1px;
                    //     position: absolute;
                    //     background: #fff;
                    //     opacity: 0;
                    //     bottom: 2px;
                    //     left: 0;
                    //     transform: translateY(4px);
                    //     transition: opacity .2s ease-in-out, transform .2s ease-in-out;
                    // }
                }
            }
        }
    }

    &__contact {
        max-width: none;
        text-align: right;

        @include below(768px) {
            text-align: left;
        }

        ul {
            li {
                list-style: none;
                margin-bottom: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;

                @include below(768px) {
                    justify-content: flex-start;
                }

                img {
                    display: inline-block;
                    margin-left: 30px;
                }

                a {
                    color: #fff;
                    font-size: 24px;
                    line-height: 36px;
                    font-weight: 400;
                    text-decoration: none;
                }
            }
        }
    }
}
