.contact-block {
    width: 100%;
    margin: 80px 0;
    border: 1px solid #D8D8D8;

    @include below(768px) {
        margin: 55px 0;
    }

    &__header {
        width: 100%;
        padding: 40px 25px;
        color: #000;
        border-bottom: 1px solid #D8D8D8;
        font-size: 24px;
        line-height: 29px;
        font-weight: 400;

        @include below(620px) {
            font-size: 20px;
        }
    }
}

.contact-block__list {
    width: 100%;
    padding: 0 !important;

    li {
        width: 100%;
        list-style: none;
        margin-bottom: 0 !important;
        border-bottom: 1px solid #D8D8D8;
        transition: background .2s ease-in-out;

        &:last-of-type {
            border-bottom: none;
        }

        &:hover {
            background: rgba(#000, 0.03);

            a:after {
                transform: translateX(3px);
            }
        }
    }

    a {
        width: 100%;
        color: #000;
        padding: 15px;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        &:after {
            content: "";
            margin: 2px 0 0 0;
            display: inline-block;
            width: 12px;
            height: 12px;
            right: 25px;
            position: absolute;
            background-image: url('../img/black-arrow-right.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            will-change: transform;
            transition: transform .2s linear;
            backface-visibility: hidden;
        }
    }

    h4 {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
    }
}

.contact-block__list-image {
    width: 60px;
    margin-right: 25px;

    img {
        max-width: 100%;
        display: block;
    }
}
