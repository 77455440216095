.law-area-select {
    font-size: 16px;
    line-height: 36px;
    text-align: left;
    border: 1px solid #fff;
    border-radius: 5px;
    max-width: none;
    margin: 50px 0 10px 0;
    padding: 15px 30px;
    width: 100%;
    display: none;

    @include below(768px) {
        display: block;
    }

    &__current {
        width: 100%;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        display: flex;
        cursor: pointer;

        &:after {
            content: "";
            display: inline-block;
            width: 14px;
            height: 7px;
            vertical-align: middle;
            background-image: url("../img/white-arrow-down.svg");
            background-repeat: no-repeat;
            background-size: contain;
            will-change: transform;
            transition: transform .2s linear;
        }

        &.open {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    &__menu {
        overflow: hidden;
        height: 0;
        transition: height .3s ease-in-out;
        padding: 0 20px;

        li {
            list-style: none;
            cursor: pointer;
            opacity: 0.6;
            position: relative;
            transition: opacity .2s ease-in-out;

            a {
                color: #fff;
                text-decoration: none;
                font-size: 16px;
                line-height: 36px;
                font-weight: 400;
            }

            &:hover {
                opacity: 1;
            }

            &.current_page_item {
                opacity: 1;

                &:before {
                    opacity: 1;
                }
            }

            &:before {
                content: "";
                display: inline-block;
                color: #fff;
                position: absolute;
                width: 6px;
                left: -20px;
                height: 6px;
                background: #fff;
                border-radius: 100%;
                top: 50%;
                opacity: 0;
                transform: translateY(-50%);
            }
        }
    }

}
