.default-page {
    width: 100%;
    max-width: 1140px;
    padding: 80px 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @include below(768px) {
        flex-direction: column;
        padding: 30px 20px;
    }

    &__sidebar {
        width: 30%;
        max-width: 350px;
        padding: 0 20px;

        @include below(768px) {
            width: 100%;
            max-width: none;
            padding: 0;
        }
    }

    &__content {
        width: 70%;
        max-width: 729px;

        @include below(768px) {
            width: 100%;
            max-width: none;
        }
    }
}
