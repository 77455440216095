.information-banner {
    width: 100%;
    padding: 70px 20px;
    background-color: $beige;

    @include below(620px) {
        padding: 40px 20px;
    }

    &__inner {
        width: 100%;
        max-width: $contentWidth;
        margin: 0 auto;

        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }

    &__title {
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 15px;

        @include below(620px) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: #817F7C;
        float: left;

        a {
            color: #817F7C;
            text-decoration: none;
        }

        @include below(620px) {
            float: none;
            display: block;

            span {
                display: none;
            }
        }

        &:last-of-type {
            margin-left: 5px;

            @include below(620px) {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }
}
