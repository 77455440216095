.link-block {
    width: 100%;
    display: flex;
    flex-direction: row;

    @include below(900px) {
        flex-direction: column;
    }

    &__link {
        width: 50%;
        display: block;
        position: relative;

        @include below(900px) {
            width: 100%;
            float: none;
        }

        &:hover {
            .link-block__image-holder img {

            }

            .overlay {
                background-color: rgba(#000, 0.5);
            }
        }
    }

    &__image-holder {
        width: 100%;
        z-index: 0;

        img {
            max-width: 100%;
            display: block;
        }
    }

    &__title {
        z-index: 20;
        font-size: 36px;
        line-height: 42px;
        color: #fff;
        width: 100%;
        text-align: center;
        @include align-both(absolute);

        @include below(500px) {
            font-size: 30px;
            text-align: center;
        }
    }
}
