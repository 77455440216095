@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */


// Settings
// ==================================================
$hamburger-padding-x           : 15px !default;
$hamburger-padding-y           : 15px !default;
$hamburger-layer-width         : 15px !default;
$hamburger-layer-height        : 1px !default;
$hamburger-layer-spacing       : 3px !default;
$hamburger-layer-color         : #fff !default;
$hamburger-layer-border-radius : 2px !default;
$hamburger-hover-opacity       : 0.8 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;


// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
  collapse,
  collapse-r,
) !default;

// Hamburger
// ==================================================
.hamburger {
  // padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }
    @else {
      opacity: $hamburger-hover-opacity;
    }
  }

  &.is-active {
    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter;
      }
      @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;

  @include below(960px) {
    width: 40px;
    height: 1px * 3 + 8px * 2;
  }
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: 2px / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;

    @include below(960px) {
      width: 40px;
      height: 1px;
    }
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;

    @include below(960px) {
        top: (8px + 1px) * -1;
    }
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;

    @include below(960px) {
        bottom: (8px + 1px) * -1;
    }
  }
}

.hamburger--collapse {
  .hamburger-inner {
     top: auto;
     bottom: 0;
     transition-duration: 0.13s;
     transition-delay: 0.13s;
     transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

     &::after {
      top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                   opacity 0.1s linear;

       @include below(960px) {
           top: (8px * 2 + 1px * 2) * -1;
       }
     }

     &::before {
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                   transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
     }
  }

  &.is-active {
     .hamburger-inner {
      transform: translate3d(0, ($hamburger-layer-spacing + $hamburger-layer-height) * -1, 0) rotate(-45deg);
      transition-delay: 0.22s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      @include below(960px) {
          transform: translate3d(0, (8px + 2px) * -1, 0) rotate(-45deg);
      }

      &::after {
         top: 0;
         opacity: 0;
         transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                     opacity 0.1s 0.22s linear;
      }

      &::before {
         top: 0;
         transform: rotate(-90deg);
         transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                     transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
     }
  }
}
