.accordion {
    width: 100%;
    max-width: 100%;
    display: block;
    margin: 55px 0;

    &__item {
        padding: 10px 0 20px 0;
        border-bottom: 1px solid #D8D8D8;
    }

    &__header {
        padding: 20px 0 10px 0;
        cursor: pointer;
        font-size: 24px;
        line-height: 46px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:after {
            content: "";
            display: inline-block;
            width: 14px;
            height: 7px;
            vertical-align: middle;
            margin-left: 24px;
            background-image: url("../img/black-arrow-down.svg");
            background-repeat: no-repeat;
            background-size: contain;
            will-change: transform;
            transition: transform .2s linear;
        }

        &.open {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    &__content {
        overflow: hidden;
        height: 0;
        transition: height .2s ease-in-out;

        h2, h3 {
            font-weight: 500;
        }

        p {
            margin: 20px 0;
        }

        ul, ol {
            padding: 20px;

            li {
                margin-bottom: 5px;
            }
        }

        &-small-title {
            font-size: 16px;
            line-height: 29px;
            font-weight: 500;
            margin: 20px 0 10px 0;
        }

        &-contact {
            font-size: 16px;
            line-height: 29px;
            font-weight: 400;
            display: block;
            text-decoration: none;
            color: #000;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
