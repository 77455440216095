.image-block {
    margin: 50px 0;

    img {
        max-width: 100%;
        display: block;
    }

    &__text {
        margin-top: 10px;
        font-size: 14px;
        line-height: 26px;
        font-weight: 400;
        text-align: center;
    }
}
