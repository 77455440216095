.big-text-block {
    width: 100%;
    padding: 160px 20px 150px 20px;
    background: #fff;

    @include below(620px) {
        padding: 70px 20px;
    }

    &__inner {
        width: 100%;
        max-width: $contentWidth;
        margin: 0 auto;
    }

    &__title {
        font-size: 84px;
        line-height: 94px;
        color: #000;
        margin-bottom: 35px;

        @include below(960px) {
            font-size: 74px;
            line-height: 84px;
        }

        @include below(620px) {
            font-size: 46px;
            line-height: 56px;
        }
    }

    &__text {
        max-width: 760px;
        width: 100%;

        p {
            font-size: 24px;
            line-height: 36px;

            @include below(620px) {
                font-size: 16px;
                line-height: 29px;
            }
        }
    }
}
