.job-ads {
    width: 100%;
    background: $beige;
    padding: 105px 0 180px 0;

    @include below(620px) {
        padding: 55px 0;
    }

    &.no-jobs {
        padding: 200px 0;
    }

    &__list {
        width: 100%;
        max-width: 1140px;
        padding: 0 20px;
        margin: 0 auto;
    }

    &__title {
        margin: 0 0 50px 0;
        font-size: 24px;
        line-height: 36px;
        font-weight: 400;

        @include below(620px) {
            margin: 0 0 25px 0;
        }
    }

    &__no-jobs {
        text-align: center;
        max-width: 715px;
        width: 100%;
        margin: 0 auto;
    }

    &__job {
        width: 100%;
        display: block;
        background: #FFFFFF;
        color: #000000;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
        margin: 0 0 25px 0;
        padding: 62px 9%;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        will-change: transform;
        transition: box-shadow .1s linear;

        @include below(960px) {
            padding: 60px 30px;
        }

        @include below(768px) {
            padding: 32px 20px;
        }

        &:hover {
            box-shadow: 0 6px 10px 0 rgba(0,0,0,0.05);

            .job-ads__job-button:after {
                    transform: translateX(3px);
                }
        }

        &-title {
            font-size: 24px;
            line-height: 36px;
            font-weight: 500;
            max-width: 90%;

            @include below(768px) {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &-button {

            @include below(620px) {
                span {
                    display: none;
                }
            }

            &:after {
                content: "";
                margin: 2px 0 0 15px;
                display: inline-block;
                width: 12px;
                height: 12px;
                background-image: url('../img/black-arrow-right.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                will-change: transform;
                transition: transform .2s linear;
                backface-visibility: hidden;
            }
        }
    }
}
