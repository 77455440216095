.page-header {
    width: 100%;
    height: 450px;
    max-height: 95vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    position: relative;
    display: flex;
    align-items: flex-end;

    @include below(500px) {
        height: 326px;
        max-height: none;
    }

    &__short {
        height: 145px;

        @include below(500px) {
            height: 100px;
            max-height: none;
        }
    }

    &__inner {
        width: 100%;
        margin: 0 auto;
        z-index: 10;
        max-width: 1140px;
        padding: 0 20px;
    }

    &__title {
        width: 100%;
        color: #fff;
        max-width: 620px;
        margin-bottom: 45px;
        opacity: 0;
        transform: translateY(10px);
        animation: fadeTitle .5s ease-in-out forwards;
        animation-delay: .1s;
        margin-left: -6px;

        @include below(960px) {
            font-size: 74px;
            line-height: 84px;
        }

        @include below(620px) {
            font-size: 46px;
            line-height: 56px;
            margin: 0 0 20px 0;
        }
    }
}

@keyframes fadeTitle {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
