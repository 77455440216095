@font-face {
    font-family: 'Maison Neue';
    src: url('../fonts/MaisonNeue-Book.woff2') format('woff2'),
        url('../fonts/MaisonNeue-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('../fonts/MaisonNeue-Medium.woff2') format('woff2'),
        url('../fonts/MaisonNeue-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

// @font-face {
//     font-family: 'Maison Neue';
//     src: url('../fonts/MaisonNeue-Bold.woff2') format('woff2'),
//         url('../fonts/MaisonNeue-Bold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
// }
