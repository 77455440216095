article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased; }
  body * {
    box-sizing: border-box; }

a:focus {
  outline: 0; }

a:active,
a:hover {
  outline: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.clearfix {
  display: inline-block; }

html[xmlns] .clearfix {
  display: block; }

* html .clearfix {
  height: 1%; }

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal; }

ul {
  margin: 0;
  padding: 0; }

/**
 * May this be a helping hand in the land of media queries.
 * Nothing is a waste of time if you use the experience wisely.
 */
/**
 * Retina
 */
.title-xxl {
  font-size: 84px;
  line-height: 94px; }

strong, b {
  font-weight: 500 !important; }

.center {
  margin: 0 auto; }

.overlay {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color .3s ease-in-out, opacity .3s ease-in-out; }

.row {
  width: 100%;
  padding: 0 20px; }

.site-wrapper {
  width: 100%;
  position: relative;
  background: #fff; }

p {
  font-size: 16px;
  line-height: 29px;
  font-weight: 400; }

.underline-hover {
  display: block;
  position: relative; }
  .underline-hover:hover:after {
    opacity: 1;
    transform: translateY(0); }
  .underline-hover:after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    background: #000;
    opacity: 0;
    transform: translateY(4px);
    transition: opacity .2s ease-in-out, transform .2s ease-in-out; }

.background-logo {
  position: absolute;
  z-index: -2;
  top: 150px;
  width: 100%;
  text-align: center; }

@font-face {
  font-family: 'Maison Neue';
  src: url("../fonts/MaisonNeue-Book.woff2") format("woff2"), url("../fonts/MaisonNeue-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Maison Neue';
  src: url("../fonts/MaisonNeue-Medium.woff2") format("woff2"), url("../fonts/MaisonNeue-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

.contact-page {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 100px 20px; }
  @media only screen and (max-width: 620px) {
    .contact-page {
      flex-direction: column;
      padding: 45px 20px; } }
  .contact-page__column {
    width: 50%;
    max-width: 450px; }
    @media only screen and (max-width: 620px) {
      .contact-page__column {
        width: 100%;
        max-width: none; } }
    .contact-page__column img {
      max-width: 100%;
      display: block; }
  .contact-page__contact {
    padding: 0 30px 0 0; }
    @media only screen and (max-width: 620px) {
      .contact-page__contact {
        padding: 0; } }
    .contact-page__contact li {
      list-style: none;
      margin-bottom: 40px;
      font-size: 24px;
      line-height: 24px;
      color: #000; }
      @media only screen and (max-width: 400px) {
        .contact-page__contact li {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 25px; } }
      .contact-page__contact li a {
        color: #000;
        text-decoration: none; }
        .contact-page__contact li a.contact-page__map-link {
          display: block;
          margin-top: 8px;
          text-decoration: underline; }
    .contact-page__contact-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      display: block;
      margin-bottom: 10px; }
  .contact-page__reception-hours {
    text-align: right;
    margin: 35px 0 0 0;
    font-size: 16px;
    line-height: 22px; }
    @media only screen and (max-width: 620px) {
      .contact-page__reception-hours {
        text-align: left;
        margin: 25px 0 0 0; } }
    .contact-page__reception-hours h4 {
      font-weight: 500; }
    .contact-page__reception-hours p {
      font-weight: 400; }

.employees {
  width: 100%;
  padding: 105px 0 150px 0;
  background: #F4F0EC; }
  @media only screen and (max-width: 620px) {
    .employees {
      padding: 50px 0; } }
  .employees__header {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto 70px auto;
    display: flex;
    padding: 0 20px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between; }
    @media only screen and (max-width: 620px) {
      .employees__header {
        flex-direction: column;
        margin-bottom: 30px; } }
    .employees__header-title {
      font-size: 24px;
      line-height: 36px;
      font-weight: 400; }
      @media only screen and (max-width: 620px) {
        .employees__header-title {
          font-size: 20px;
          line-height: 26px;
          margin: 0 0 25px 0; } }
  .employees__grid {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 5px;
    position: relative; }

.employee__card {
  width: calc(100% / 4);
  max-width: calc(100% / 4);
  padding: 0 15px 60px 15px;
  text-decoration: none;
  color: #000;
  transition: all .3s ease-in-out; }
  .employee__card:hover .employee__image .overlay {
    opacity: 1; }
  .employee__card:hover .overlay__text {
    top: 50%;
    opacity: 1; }
  .employee__card.animate-out {
    opacity: 0; }
  .employee__card.hide {
    display: none; }
    .employee__card.hide.no-filter {
      display: block !important; }
  @media only screen and (max-width: 768px) {
    .employee__card {
      width: calc(100% / 3);
      max-width: calc(100% / 3); } }
  @media only screen and (max-width: 620px) {
    .employee__card {
      width: calc(100% / 2);
      max-width: calc(100% / 2); } }
  @media only screen and (max-width: 420px) {
    .employee__card {
      width: 100%;
      max-width: 100%;
      padding-bottom: 40px; } }

.employee__image {
  width: 100%;
  display: block;
  margin-bottom: 33px;
  position: relative; }
  .employee__image .overlay {
    opacity: 0;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.35); }
    .employee__image .overlay__text {
      display: block;
      width: 100%;
      color: #fff;
      text-align: center;
      z-index: 10;
      font-size: 20px;
      line-height: 22px;
      font-weight: 400;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      top: 52%;
      transition: opacity .3s ease-in-out, top .3s ease-in-out; }
  .employee__image img {
    max-width: 100%;
    display: block; }

.employee__name {
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  margin: 0 0 15px 0; }

.employee__jobtitle {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400; }

.employee__filter-chosen {
  cursor: pointer;
  position: relative; }
  @media only screen and (max-width: 620px) {
    .employee__filter-chosen {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; } }
  .employee__filter-chosen:after {
    content: "";
    display: inline-block;
    width: 14px;
    height: 7px;
    vertical-align: middle;
    margin-left: 24px;
    background-image: url("../img/black-arrow-down.svg");
    background-repeat: no-repeat;
    background-size: contain;
    will-change: transform;
    transition: transform .2s linear; }
  .employee__filter-chosen.open:after {
    transform: rotate(180deg); }

.employee__filter {
  width: 100%;
  max-width: 350px;
  font-size: 24px;
  line-height: 46px;
  font-weight: 400;
  text-align: right; }
  @media only screen and (max-width: 620px) {
    .employee__filter {
      font-size: 16px;
      line-height: 36px;
      text-align: left;
      padding: 15px 30px;
      border: 1px solid #000;
      border-radius: 5px;
      max-width: none; } }
  .employee__filter-list {
    text-align: right;
    overflow: hidden;
    height: 0;
    transition: height .3s ease-in-out; }
    @media only screen and (max-width: 620px) {
      .employee__filter-list {
        text-align: left;
        padding: 0 20px; } }
    .employee__filter-list-item {
      list-style: none;
      cursor: pointer;
      opacity: 0.5;
      position: relative;
      transition: opacity .2s ease-in-out; }
      .employee__filter-list-item:hover {
        opacity: 1; }
      .employee__filter-list-item.active {
        opacity: 1; }
        .employee__filter-list-item.active:after {
          opacity: 1; }
      .employee__filter-list-item:after {
        content: "";
        display: inline-block;
        color: #000;
        position: absolute;
        width: 6px;
        right: -20px;
        height: 6px;
        background: #000;
        border-radius: 100%;
        top: 50%;
        opacity: 0;
        transform: translateY(-50%); }
        @media only screen and (max-width: 620px) {
          .employee__filter-list-item:after {
            left: -20px; } }

.single-employee-breadcrumb {
  width: 100%;
  padding: 25px 20px;
  background: #F4F0EC; }
  .single-employee-breadcrumb__inner {
    width: 100%;
    max-width: 1140px;
    padding: 0 20px;
    margin: 0 auto; }
    @media only screen and (max-width: 620px) {
      .single-employee-breadcrumb__inner {
        padding: 0; } }
    .single-employee-breadcrumb__inner a {
      text-decoration: none;
      color: #000; }

.single-employee-header {
  width: 100%;
  max-width: 1140px;
  padding: 50px 20px;
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative; }
  @media only screen and (max-width: 620px) {
    .single-employee-header {
      flex-direction: column;
      align-items: flex-start;
      border: none;
      padding-bottom: 0; } }
  .single-employee-header__text, .single-employee-header__image {
    width: 50%; }
    @media only screen and (max-width: 620px) {
      .single-employee-header__text, .single-employee-header__image {
        width: 100%; } }
  .single-employee-header__text {
    max-width: 50%;
    padding: 0 30px 0 0; }
    @media only screen and (max-width: 620px) {
      .single-employee-header__text {
        max-width: none; } }
    .single-employee-header__text h4 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 7px; }
    .single-employee-header__text a {
      color: #000;
      text-decoration: none;
      font-size: 24px;
      line-height: 24px;
      font-weight: 400;
      display: block;
      margin-bottom: 40px; }
      @media only screen and (max-width: 500px) {
        .single-employee-header__text a {
          font-size: 20px;
          line-height: 24px; } }
  .single-employee-header__name {
    font-size: 58px;
    line-height: 58px;
    font-weight: 400;
    margin-bottom: 20px; }
    @media only screen and (max-width: 500px) {
      .single-employee-header__name {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 10px; } }
  .single-employee-header__jobtitle {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    padding: 0 0 55px 0;
    margin: 0 0 65px 0;
    position: relative; }
    @media only screen and (max-width: 500px) {
      .single-employee-header__jobtitle {
        font-size: 16px;
        line-height: 42px;
        padding: 0 0 15px 0;
        margin: 0 0 30px 0; } }
    .single-employee-header__jobtitle:after {
      content: "";
      width: 200px;
      height: 1px;
      background: #000;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0; }
  .single-employee-header__image {
    max-width: 544px; }
    @media only screen and (max-width: 620px) {
      .single-employee-header__image {
        max-width: none; } }
    .single-employee-header__image img {
      max-width: 100%;
      display: block; }

.single-employee-content {
  width: 100%;
  max-width: 1140px;
  padding: 0 20px;
  margin: 40px auto 100px auto; }
  @media only screen and (max-width: 500px) {
    .single-employee-content {
      margin: 40px auto; } }
  .single-employee-content__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 60px;
    align-items: flex-start; }
    @media only screen and (max-width: 500px) {
      .single-employee-content__header {
        margin-bottom: 10px; } }
  .single-employee-content__title {
    font-size: 58px;
    line-height: 58px;
    font-weight: 400; }
    @media only screen and (max-width: 500px) {
      .single-employee-content__title {
        font-size: 32px;
        line-height: 42px; } }
  .single-employee-content__text {
    width: 100%;
    max-width: 634px;
    font-size: 16px;
    line-height: 29px;
    font-weight: 400; }
    .single-employee-content__text p {
      margin: 20px 0; }
    .single-employee-content__text ul, .single-employee-content__text ol {
      padding: 0 20px; }
      .single-employee-content__text ul li, .single-employee-content__text ol li {
        line-height: 20px;
        margin-bottom: 10px; }

.v-card-link {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  padding: 15px;
  display: block;
  width: 100%;
  max-width: 266px;
  color: #000;
  border: 1px solid #E2E2E2;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  transition: background .2s linear, color .2s linear; }
  @media only screen and (max-width: 620px) {
    .v-card-link {
      display: none; } }
  @media only screen and (max-width: 420px) {
    .v-card-link {
      max-width: none; } }
  .v-card-link.mobile {
    display: none; }
    @media only screen and (max-width: 620px) {
      .v-card-link.mobile {
        display: block;
        margin-top: 40px; } }
  .v-card-link:hover {
    background: #000;
    color: #fff; }

.default-page {
  width: 100%;
  max-width: 1140px;
  padding: 80px 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between; }
  @media only screen and (max-width: 768px) {
    .default-page {
      flex-direction: column;
      padding: 30px 20px; } }
  .default-page__sidebar {
    width: 30%;
    max-width: 350px;
    padding: 0 20px; }
    @media only screen and (max-width: 768px) {
      .default-page__sidebar {
        width: 100%;
        max-width: none;
        padding: 0; } }
  .default-page__content {
    width: 70%;
    max-width: 729px; }
    @media only screen and (max-width: 768px) {
      .default-page__content {
        width: 100%;
        max-width: none; } }

.site-navigation {
  top: 0;
  left: 0;
  z-index: 999;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 50px 50px 0 50px;
  height: 95px; }
  @media only screen and (max-width: 960px) {
    .site-navigation {
      padding: 0 20px;
      height: 100px;
      align-items: center; } }
  .site-navigation__logo {
    width: 15%;
    display: block; }
    @media only screen and (max-width: 960px) {
      .site-navigation__logo {
        width: 50%; } }
    .site-navigation__logo svg {
      width: 200px;
      height: 45px; }
      @media only screen and (max-width: 600px) {
        .site-navigation__logo svg {
          width: 180px;
          height: 45px; } }
  .site-navigation__nav {
    width: 65%;
    text-align: center; }
    .site-navigation__nav .menu .menu-item {
      list-style: none;
      display: inline-block;
      margin: 0 15px; }
      .site-navigation__nav .menu .menu-item a {
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        position: relative; }
        .site-navigation__nav .menu .menu-item a:hover:after {
          opacity: 1;
          transform: translateY(0); }
        .site-navigation__nav .menu .menu-item a:after {
          content: "";
          width: 100%;
          height: 1px;
          position: absolute;
          background: #fff;
          opacity: 0;
          bottom: -2px;
          left: 0;
          transform: translateY(4px);
          transition: opacity .2s ease-in-out, transform .2s ease-in-out; }
    @media only screen and (max-width: 960px) {
      .site-navigation__nav {
        display: none; } }
  .site-navigation__lang-switch {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    margin: 0 15px 0 0;
    position: relative; }
    .site-navigation__lang-switch:hover:after {
      opacity: 1;
      transform: translateY(0); }
    .site-navigation__lang-switch:after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      background: #fff;
      opacity: 0;
      bottom: -2px;
      left: 0;
      transform: translateY(4px);
      transition: opacity .2s ease-in-out, transform .2s ease-in-out; }
  .site-navigation__button {
    width: 20%;
    color: #fff;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    text-align: right;
    min-width: 210px; }
    .site-navigation__button-text {
      margin-right: 10px; }
    @media only screen and (max-width: 960px) {
      .site-navigation__button {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        min-width: none; }
        .site-navigation__button-text {
          display: none; } }

.site-menu {
  width: 100%;
  max-height: 0px;
  background: #493D37;
  transition: max-height .3s ease-in-out, padding .3s ease-in-out;
  overflow: hidden;
  pointer-events: none;
  padding-right: 20px;
  padding-left: 20px; }
  .site-menu.open {
    max-height: 700px;
    pointer-events: all;
    padding-top: 60px;
    padding-bottom: 60px; }
    .site-menu.open .site-menu__inner {
      opacity: 1;
      transform: translateY(0); }
  .site-menu__inner {
    width: 100%;
    max-width: 1140px;
    padding: 0 20px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    transform: translateY(5px);
    transition: opacity .2s ease-in-out, transform .2s ease-in-out;
    transition-delay: .2s; }
    @media only screen and (max-width: 760px) {
      .site-menu__inner {
        justify-content: flex-end; } }
  .site-menu__title {
    font-size: 48px;
    line-height: 60px;
    font-weight: 400;
    color: #fff; }
    @media only screen and (max-width: 960px) {
      .site-menu__title {
        font-size: 38px; } }
    @media only screen and (max-width: 760px) {
      .site-menu__title {
        display: none; } }
  .site-menu__head-menu {
    display: none; }
    @media only screen and (max-width: 960px) {
      .site-menu__head-menu {
        display: block; } }
    .site-menu__head-menu .menu {
      text-align: right; }
      .site-menu__head-menu .menu li {
        list-style: none; }
        @media only screen and (max-width: 960px) {
          .site-menu__head-menu .menu li {
            margin-bottom: 10px; } }
        .site-menu__head-menu .menu li a {
          color: #fff;
          display: block;
          text-decoration: none;
          font-size: 24px;
          line-height: 36px; }
  .site-menu__law-menu-button {
    color: #fff;
    font-size: 24px;
    line-height: 36px;
    cursor: pointer;
    margin-top: 35px;
    text-align: right;
    display: none; }
    @media only screen and (max-width: 960px) {
      .site-menu__law-menu-button {
        display: block; } }
    .site-menu__law-menu-button:after {
      content: "";
      display: inline-block;
      width: 14px;
      height: 7px;
      vertical-align: middle;
      margin-left: 24px;
      background-image: url("../img/white-arrow-down.svg");
      background-repeat: no-repeat;
      background-size: contain;
      will-change: transform;
      transition: transform .1s linear; }
  @media only screen and (max-width: 960px) {
    .site-menu__law-menu {
      max-height: 0px;
      transition: max-height .3s linear;
      overflow: hidden;
      pointer-events: none;
      margin-top: 10px; }
      .site-menu__law-menu.open {
        max-height: 300px;
        pointer-events: all;
        overflow: visible; } }
  .site-menu__law-menu .menu {
    text-align: right; }
    .site-menu__law-menu .menu:hover li a {
      opacity: 0.6; }
      .site-menu__law-menu .menu:hover li a:hover {
        opacity: 1; }
    .site-menu__law-menu .menu li {
      list-style: none; }
      .site-menu__law-menu .menu li a {
        color: #fff;
        opacity: 1;
        display: inline-block;
        text-decoration: none;
        font-size: 16px;
        line-height: 34px;
        transition: opacity .2s ease-in-out;
        position: relative; }
        .site-menu__law-menu .menu li a:hover {
          opacity: 1; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none; }
  .hamburger:hover {
    opacity: 0.8; }
  .hamburger.is-active:hover {
    opacity: 0.8; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #fff; }

.hamburger-box {
  width: 15px;
  height: 9px;
  display: inline-block;
  position: relative; }
  @media only screen and (max-width: 960px) {
    .hamburger-box {
      width: 40px;
      height: 19px; } }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: 2px / -2; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 15px;
    height: 1px;
    background-color: #fff;
    border-radius: 2px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
    @media only screen and (max-width: 960px) {
      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        width: 40px;
        height: 1px; } }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -4px; }
    @media only screen and (max-width: 960px) {
      .hamburger-inner::before {
        top: -9px; } }
  .hamburger-inner::after {
    bottom: -4px; }
    @media only screen and (max-width: 960px) {
      .hamburger-inner::after {
        bottom: -9px; } }

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -8px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
    @media only screen and (max-width: 960px) {
      .hamburger--collapse .hamburger-inner::after {
        top: -18px; } }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -4px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  @media only screen and (max-width: 960px) {
    .hamburger--collapse.is-active .hamburger-inner {
      transform: translate3d(0, -10px, 0) rotate(-45deg); } }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

.big-page-header {
  width: 100%;
  height: 835px;
  max-height: 95vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  position: relative;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 620px) {
    .big-page-header {
      height: 635px; } }
  .big-page-header__inner {
    width: 100%;
    margin: 0 auto;
    z-index: 10;
    padding: 0 20px;
    max-width: 1100px; }
  .big-page-header__title {
    width: 100%;
    color: #fff;
    max-width: 720px;
    opacity: 0;
    transform: translateY(10px);
    animation: fadeTitle .5s ease-in-out forwards;
    animation-delay: .2s; }
    @media only screen and (max-width: 960px) {
      .big-page-header__title {
        font-size: 74px;
        line-height: 84px; } }
    @media only screen and (max-width: 620px) {
      .big-page-header__title {
        font-size: 46px;
        line-height: 56px;
        text-align: center; } }

.page-header {
  width: 100%;
  height: 450px;
  max-height: 95vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  position: relative;
  display: flex;
  align-items: flex-end; }
  @media only screen and (max-width: 500px) {
    .page-header {
      height: 326px;
      max-height: none; } }
  .page-header__short {
    height: 145px; }
    @media only screen and (max-width: 500px) {
      .page-header__short {
        height: 100px;
        max-height: none; } }
  .page-header__inner {
    width: 100%;
    margin: 0 auto;
    z-index: 10;
    max-width: 1140px;
    padding: 0 20px; }
  .page-header__title {
    width: 100%;
    color: #fff;
    max-width: 620px;
    margin-bottom: 45px;
    opacity: 0;
    transform: translateY(10px);
    animation: fadeTitle .5s ease-in-out forwards;
    animation-delay: .1s;
    margin-left: -6px; }
    @media only screen and (max-width: 960px) {
      .page-header__title {
        font-size: 74px;
        line-height: 84px; } }
    @media only screen and (max-width: 620px) {
      .page-header__title {
        font-size: 46px;
        line-height: 56px;
        margin: 0 0 20px 0; } }

@keyframes fadeTitle {
  0% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.big-text-block {
  width: 100%;
  padding: 160px 20px 150px 20px;
  background: #fff; }
  @media only screen and (max-width: 620px) {
    .big-text-block {
      padding: 70px 20px; } }
  .big-text-block__inner {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto; }
  .big-text-block__title {
    font-size: 84px;
    line-height: 94px;
    color: #000;
    margin-bottom: 35px; }
    @media only screen and (max-width: 960px) {
      .big-text-block__title {
        font-size: 74px;
        line-height: 84px; } }
    @media only screen and (max-width: 620px) {
      .big-text-block__title {
        font-size: 46px;
        line-height: 56px; } }
  .big-text-block__text {
    max-width: 760px;
    width: 100%; }
    .big-text-block__text p {
      font-size: 24px;
      line-height: 36px; }
      @media only screen and (max-width: 620px) {
        .big-text-block__text p {
          font-size: 16px;
          line-height: 29px; } }

.link-block {
  width: 100%;
  display: flex;
  flex-direction: row; }
  @media only screen and (max-width: 900px) {
    .link-block {
      flex-direction: column; } }
  .link-block__link {
    width: 50%;
    display: block;
    position: relative; }
    @media only screen and (max-width: 900px) {
      .link-block__link {
        width: 100%;
        float: none; } }
    .link-block__link:hover .overlay {
      background-color: rgba(0, 0, 0, 0.5); }
  .link-block__image-holder {
    width: 100%;
    z-index: 0; }
    .link-block__image-holder img {
      max-width: 100%;
      display: block; }
  .link-block__title {
    z-index: 20;
    font-size: 36px;
    line-height: 42px;
    color: #fff;
    width: 100%;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
    @media only screen and (max-width: 500px) {
      .link-block__title {
        font-size: 30px;
        text-align: center; } }

.address-block {
  width: 100%;
  padding: 150px 20px;
  background-color: #F4F0EC; }
  @media only screen and (max-width: 620px) {
    .address-block {
      padding: 65px 20px 95px 20px; } }
  .address-block__inner {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row; }
    @media only screen and (max-width: 620px) {
      .address-block__inner {
        flex-direction: column; } }
  .address-block__column {
    width: 50%;
    padding: 0 20px 0 0; }
    @media only screen and (max-width: 620px) {
      .address-block__column {
        width: 100%;
        max-width: none;
        padding: 30px 0 0 0; } }
    .address-block__column-title {
      font-size: 16px;
      line-height: 24px;
      color: #000;
      font-weight: 500;
      margin: 0 0 10px 0; }
    .address-block__column-text p {
      font-size: 24px;
      line-height: 36px;
      color: #000;
      font-weight: 400; }
      @media only screen and (max-width: 620px) {
        .address-block__column-text p {
          font-size: 20px;
          line-height: 28px; } }

.information-banner {
  width: 100%;
  padding: 70px 20px;
  background-color: #F4F0EC; }
  @media only screen and (max-width: 620px) {
    .information-banner {
      padding: 40px 20px; } }
  .information-banner__inner {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto; }
    .information-banner__inner:after {
      content: "";
      display: table;
      clear: both; }
  .information-banner__title {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 15px; }
    @media only screen and (max-width: 620px) {
      .information-banner__title {
        font-size: 20px;
        line-height: 24px; } }
  .information-banner__text {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: #817F7C;
    float: left; }
    .information-banner__text a {
      color: #817F7C;
      text-decoration: none; }
    @media only screen and (max-width: 620px) {
      .information-banner__text {
        float: none;
        display: block; }
        .information-banner__text span {
          display: none; } }
    .information-banner__text:last-of-type {
      margin-left: 5px; }
      @media only screen and (max-width: 620px) {
        .information-banner__text:last-of-type {
          margin-left: 0;
          margin-top: 10px; } }

.image-text {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 90px 20px 70px 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between; }
  @media only screen and (max-width: 768px) {
    .image-text {
      flex-direction: column;
      padding: 45px 20px; } }
  .image-text__column {
    width: 50%; }
    @media only screen and (max-width: 768px) {
      .image-text__column {
        width: 100%; } }
    .image-text__column:first-of-type {
      max-width: 545px;
      padding-right: 30px; }
      @media only screen and (max-width: 768px) {
        .image-text__column:first-of-type {
          max-width: none;
          padding-right: 0; } }
    @media only screen and (max-width: 768px) {
      .image-text__column-text {
        order: 2; } }
    .image-text__column-image {
      max-width: 451px; }
      .image-text__column-image .image-text__title {
        display: none; }
        @media only screen and (max-width: 768px) {
          .image-text__column-image .image-text__title {
            display: block; } }
      @media only screen and (max-width: 768px) {
        .image-text__column-image {
          max-width: none;
          order: 1;
          margin: 0 0 36px 0; } }
      .image-text__column-image img {
        max-width: 100%;
        display: block; }
  .image-text__title {
    font-size: 58px;
    line-height: 58px;
    font-weight: 400;
    margin: 0 0 60px 0; }
    @media only screen and (max-width: 768px) {
      .image-text__title {
        display: none;
        margin: 0 0 36px 0; } }
    @media only screen and (max-width: 500px) {
      .image-text__title {
        font-size: 32px;
        line-height: 42px; } }
  .image-text__text h2, .image-text__text h3 {
    font-weight: 500; }
  .image-text__text p {
    margin: 20px 0; }
    .image-text__text p a {
      color: #111114;
      text-decoration: underline; }
  .image-text__text a {
    color: #111114;
    text-decoration: underline; }

.job-ads {
  width: 100%;
  background: #F4F0EC;
  padding: 105px 0 180px 0; }
  @media only screen and (max-width: 620px) {
    .job-ads {
      padding: 55px 0; } }
  .job-ads.no-jobs {
    padding: 200px 0; }
  .job-ads__list {
    width: 100%;
    max-width: 1140px;
    padding: 0 20px;
    margin: 0 auto; }
  .job-ads__title {
    margin: 0 0 50px 0;
    font-size: 24px;
    line-height: 36px;
    font-weight: 400; }
    @media only screen and (max-width: 620px) {
      .job-ads__title {
        margin: 0 0 25px 0; } }
  .job-ads__no-jobs {
    text-align: center;
    max-width: 715px;
    width: 100%;
    margin: 0 auto; }
  .job-ads__job {
    width: 100%;
    display: block;
    background: #FFFFFF;
    color: #000000;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    margin: 0 0 25px 0;
    padding: 62px 9%;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    will-change: transform;
    transition: box-shadow .1s linear; }
    @media only screen and (max-width: 960px) {
      .job-ads__job {
        padding: 60px 30px; } }
    @media only screen and (max-width: 768px) {
      .job-ads__job {
        padding: 32px 20px; } }
    .job-ads__job:hover {
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.05); }
      .job-ads__job:hover .job-ads__job-button:after {
        transform: translateX(3px); }
    .job-ads__job-title {
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
      max-width: 90%; }
      @media only screen and (max-width: 768px) {
        .job-ads__job-title {
          font-size: 16px;
          line-height: 24px; } }
    @media only screen and (max-width: 620px) {
      .job-ads__job-button span {
        display: none; } }
    .job-ads__job-button:after {
      content: "";
      margin: 2px 0 0 15px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background-image: url("../img/black-arrow-right.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      will-change: transform;
      transition: transform .2s linear;
      backface-visibility: hidden; }

.site-footer {
  width: 100%;
  background: #493d37;
  color: #fff; }
  .site-footer__header {
    width: 100%;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 160px 20px;
    display: flex;
    flex-direction: row;
    align-items: center; }
    @media only screen and (max-width: 768px) {
      .site-footer__header {
        flex-direction: column;
        align-items: flex-start;
        padding: 60px 20px; } }
  .site-footer__footer {
    width: 100%;
    background: #514540;
    padding: 35px 0; }
    .site-footer__footer-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      max-width: 1140px;
      padding: 0 20px;
      margin: 0 auto; }
      @media only screen and (max-width: 620px) {
        .site-footer__footer-inner {
          flex-direction: column;
          justify-content: flex-start; } }
    .site-footer__footer-col {
      width: 50%;
      max-width: 50%;
      color: #b5aea8; }
      @media only screen and (max-width: 620px) {
        .site-footer__footer-col {
          width: 100%;
          max-width: none; } }
    .site-footer__footer a {
      color: #b5aea8;
      text-decoration: none;
      display: inline-block;
      transition: color 0.2s ease-in-out; }
      .site-footer__footer a:hover {
        color: #fff; }
      .site-footer__footer a:after {
        content: "";
        width: 100%;
        height: 1px;
        background: #b5aea8;
        display: block;
        margin-top: 2px; }
  .site-footer__col {
    width: calc(100% / 3);
    max-width: 356px; }
    @media only screen and (max-width: 768px) {
      .site-footer__col {
        width: 100%;
        max-width: none; } }
    @media only screen and (max-width: 768px) {
      .site-footer__col:first-of-type {
        padding-bottom: 70px;
        margin-bottom: 70px;
        border-bottom: 2px solid #fff; } }
    .site-footer__col .menu {
      display: inline-block; }
      .site-footer__col .menu:hover li a {
        opacity: 0.6; }
        .site-footer__col .menu:hover li a:hover {
          opacity: 1; }
      .site-footer__col .menu li {
        list-style: none;
        margin-bottom: 10px; }
        .site-footer__col .menu li a {
          color: #fff;
          text-decoration: none;
          display: inline-block;
          font-size: 24px;
          line-height: 36px;
          font-weight: 400;
          position: relative;
          transition: opacity 0.2s ease-in-out; }
  @media only screen and (max-width: 768px) {
    .site-footer__law-menu {
      display: none; } }
  .site-footer__law-menu .menu {
    display: inline-block; }
    .site-footer__law-menu .menu:hover li a {
      opacity: 0.6; }
      .site-footer__law-menu .menu:hover li a:hover {
        opacity: 1; }
    .site-footer__law-menu .menu li {
      margin-bottom: 0; }
      .site-footer__law-menu .menu li a {
        font-size: 16px;
        line-height: 36px;
        position: relative;
        display: inline-block;
        transition: opacity 0.2s ease-in-out; }
  .site-footer__contact {
    max-width: none;
    text-align: right; }
    @media only screen and (max-width: 768px) {
      .site-footer__contact {
        text-align: left; } }
    .site-footer__contact ul li {
      list-style: none;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end; }
      @media only screen and (max-width: 768px) {
        .site-footer__contact ul li {
          justify-content: flex-start; } }
      .site-footer__contact ul li img {
        display: inline-block;
        margin-left: 30px; }
      .site-footer__contact ul li a {
        color: #fff;
        font-size: 24px;
        line-height: 36px;
        font-weight: 400;
        text-decoration: none; }

@media only screen and (max-width: 768px) {
  .sidebar-menu {
    font-size: 16px;
    line-height: 36px;
    text-align: left;
    border: 1px solid #000;
    border-radius: 5px;
    max-width: none;
    margin: 0 0 50px 0;
    padding: 0 0 15px 0; } }

.sidebar-menu__current {
  width: 100%;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: none; }
  @media only screen and (max-width: 768px) {
    .sidebar-menu__current {
      display: flex;
      padding: 15px 30px 0 30px; } }
  .sidebar-menu__current:after {
    content: "";
    display: inline-block;
    width: 14px;
    height: 7px;
    vertical-align: middle;
    background-image: url("../img/black-arrow-down.svg");
    background-repeat: no-repeat;
    background-size: contain;
    will-change: transform;
    transition: transform .2s linear; }
  .sidebar-menu__current.open:after {
    transform: rotate(180deg); }

@media only screen and (max-width: 768px) {
  .sidebar-menu__menu {
    padding: 0 30px;
    overflow: hidden;
    height: 0;
    transition: height .3s ease-in-out; } }

.sidebar-menu__menu li {
  list-style: none;
  cursor: pointer;
  opacity: 0.4;
  position: relative;
  margin-bottom: 15px;
  transition: opacity .2s ease-in-out; }
  @media only screen and (max-width: 768px) {
    .sidebar-menu__menu li {
      margin-bottom: 0; } }
  .sidebar-menu__menu li a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400; }
  .sidebar-menu__menu li:hover {
    opacity: 1; }
  .sidebar-menu__menu li.current_page_item {
    opacity: 1; }
    @media only screen and (max-width: 768px) {
      .sidebar-menu__menu li.current_page_item {
        display: none; } }
    .sidebar-menu__menu li.current_page_item:before {
      opacity: 1; }
  .sidebar-menu__menu li:before {
    content: "";
    display: inline-block;
    color: #000;
    position: absolute;
    width: 6px;
    left: -20px;
    height: 6px;
    background: #000;
    border-radius: 100%;
    top: 50%;
    opacity: 0;
    transform: translateY(-50%); }
    @media only screen and (max-width: 768px) {
      .sidebar-menu__menu li:before {
        display: none; } }

.text-block {
  width: 100%;
  max-width: 636px; }
  .text-block__title {
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
    margin: 0 0 40px 0; }
  .text-block__text h2, .text-block__text h3 {
    font-weight: 500; }
  .text-block__text p {
    margin: 20px 0; }
    .text-block__text p a {
      color: #111114;
      text-decoration: underline; }
  .text-block__text ul, .text-block__text ol {
    padding: 20px; }
    .text-block__text ul li, .text-block__text ol li {
      font-size: 16px;
      margin-bottom: 5px; }
  .text-block__text a {
    color: #111114; }
  .text-block__small-title {
    font-size: 16px;
    line-height: 29px;
    font-weight: 500;
    margin: 20px 0 10px 0; }
  .text-block__contact {
    font-size: 16px;
    line-height: 29px;
    font-weight: 400;
    display: block;
    text-decoration: none;
    color: #000; }
    .text-block__contact:hover {
      text-decoration: underline; }

.image-block {
  margin: 50px 0; }
  .image-block img {
    max-width: 100%;
    display: block; }
  .image-block__text {
    margin-top: 10px;
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
    text-align: center; }

.contact-block {
  width: 100%;
  margin: 80px 0;
  border: 1px solid #D8D8D8; }
  @media only screen and (max-width: 768px) {
    .contact-block {
      margin: 55px 0; } }
  .contact-block__header {
    width: 100%;
    padding: 40px 25px;
    color: #000;
    border-bottom: 1px solid #D8D8D8;
    font-size: 24px;
    line-height: 29px;
    font-weight: 400; }
    @media only screen and (max-width: 620px) {
      .contact-block__header {
        font-size: 20px; } }

.contact-block__list {
  width: 100%;
  padding: 0 !important; }
  .contact-block__list li {
    width: 100%;
    list-style: none;
    margin-bottom: 0 !important;
    border-bottom: 1px solid #D8D8D8;
    transition: background .2s ease-in-out; }
    .contact-block__list li:last-of-type {
      border-bottom: none; }
    .contact-block__list li:hover {
      background: rgba(0, 0, 0, 0.03); }
      .contact-block__list li:hover a:after {
        transform: translateX(3px); }
  .contact-block__list a {
    width: 100%;
    color: #000;
    padding: 15px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative; }
    .contact-block__list a:after {
      content: "";
      margin: 2px 0 0 0;
      display: inline-block;
      width: 12px;
      height: 12px;
      right: 25px;
      position: absolute;
      background-image: url("../img/black-arrow-right.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      will-change: transform;
      transition: transform .2s linear;
      backface-visibility: hidden; }
  .contact-block__list h4 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400; }

.contact-block__list-image {
  width: 60px;
  margin-right: 25px; }
  .contact-block__list-image img {
    max-width: 100%;
    display: block; }

.law-area-select {
  font-size: 16px;
  line-height: 36px;
  text-align: left;
  border: 1px solid #fff;
  border-radius: 5px;
  max-width: none;
  margin: 50px 0 10px 0;
  padding: 15px 30px;
  width: 100%;
  display: none; }
  @media only screen and (max-width: 768px) {
    .law-area-select {
      display: block; } }
  .law-area-select__current {
    width: 100%;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
    cursor: pointer; }
    .law-area-select__current:after {
      content: "";
      display: inline-block;
      width: 14px;
      height: 7px;
      vertical-align: middle;
      background-image: url("../img/white-arrow-down.svg");
      background-repeat: no-repeat;
      background-size: contain;
      will-change: transform;
      transition: transform .2s linear; }
    .law-area-select__current.open:after {
      transform: rotate(180deg); }
  .law-area-select__menu {
    overflow: hidden;
    height: 0;
    transition: height .3s ease-in-out;
    padding: 0 20px; }
    .law-area-select__menu li {
      list-style: none;
      cursor: pointer;
      opacity: 0.6;
      position: relative;
      transition: opacity .2s ease-in-out; }
      .law-area-select__menu li a {
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        line-height: 36px;
        font-weight: 400; }
      .law-area-select__menu li:hover {
        opacity: 1; }
      .law-area-select__menu li.current_page_item {
        opacity: 1; }
        .law-area-select__menu li.current_page_item:before {
          opacity: 1; }
      .law-area-select__menu li:before {
        content: "";
        display: inline-block;
        color: #fff;
        position: absolute;
        width: 6px;
        left: -20px;
        height: 6px;
        background: #fff;
        border-radius: 100%;
        top: 50%;
        opacity: 0;
        transform: translateY(-50%); }

.accordion {
  width: 100%;
  max-width: 100%;
  display: block;
  margin: 55px 0; }
  .accordion__item {
    padding: 10px 0 20px 0;
    border-bottom: 1px solid #D8D8D8; }
  .accordion__header {
    padding: 20px 0 10px 0;
    cursor: pointer;
    font-size: 24px;
    line-height: 46px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .accordion__header:after {
      content: "";
      display: inline-block;
      width: 14px;
      height: 7px;
      vertical-align: middle;
      margin-left: 24px;
      background-image: url("../img/black-arrow-down.svg");
      background-repeat: no-repeat;
      background-size: contain;
      will-change: transform;
      transition: transform .2s linear; }
    .accordion__header.open:after {
      transform: rotate(180deg); }
  .accordion__content {
    overflow: hidden;
    height: 0;
    transition: height .2s ease-in-out; }
    .accordion__content h2, .accordion__content h3 {
      font-weight: 500; }
    .accordion__content p {
      margin: 20px 0; }
    .accordion__content ul, .accordion__content ol {
      padding: 20px; }
      .accordion__content ul li, .accordion__content ol li {
        margin-bottom: 5px; }
    .accordion__content-small-title {
      font-size: 16px;
      line-height: 29px;
      font-weight: 500;
      margin: 20px 0 10px 0; }
    .accordion__content-contact {
      font-size: 16px;
      line-height: 29px;
      font-weight: 400;
      display: block;
      text-decoration: none;
      color: #000; }
      .accordion__content-contact:hover {
        text-decoration: underline; }

html, body {
  font-family: 'Maison Neue';
  position: relative; }

@keyframes animateBody {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
