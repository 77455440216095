.single-employee-breadcrumb {
    width: 100%;
    padding: 25px 20px;
    background: $beige;

    &__inner {
        width: 100%;
        max-width: 1140px;
        padding: 0 20px;
        margin: 0 auto;

        @include below(620px) {
            padding: 0;
        }

        a {
            text-decoration: none;
            color: #000;
        }
    }
}

.single-employee-header {
    width: 100%;
    max-width: 1140px;
    padding: 50px 20px;
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    // border-bottom: 1px solid $veryLightGray;

    // &:after {
    //     content: "";
    //     width: 80px;
    //     height: 1px;
    //     background: #000;
    //     display: block;
    //     position: absolute;
    //     bottom: 0;
    //     left: 20px;
    // }

    @include below(620px) {
        flex-direction: column;
        align-items: flex-start;
        border: none;
        padding-bottom: 0;
    }


    &__text, &__image {
        width: 50%;

        @include below(620px) {
            width: 100%;
        }
    }

    &__text {
        max-width: 50%;
        padding: 0 30px 0 0;

        @include below(620px) {
            max-width: none;
        }

        h4 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 7px;
        }

        a {
            color: #000;
            text-decoration: none;
            font-size: 24px;
            line-height: 24px;
            font-weight: 400;
            display: block;
            margin-bottom: 40px;

            @include below(500px) {
                font-size: 20px;
                line-height: 24px;
            }
        }
    }

    &__name {
        font-size: 58px;
        line-height: 58px;
        font-weight: 400;
        margin-bottom: 20px;

        @include below(500px) {
            font-size: 32px;
            line-height: 42px;
            margin-bottom: 10px;
        }
    }

    &__jobtitle {
        font-size: 24px;
        line-height: 36px;
        font-weight: 400;
        padding: 0 0 55px 0;
        margin: 0 0 65px 0;
        position: relative;

        @include below(500px) {
            font-size: 16px;
            line-height: 42px;
            padding: 0 0 15px 0;
            margin: 0 0 30px 0;
        }

        &:after {
            content: "";
            width: 200px;
            height: 1px;
            background: #000;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    &__image {
        max-width: 544px;

        @include below(620px) {
            max-width: none;
        }

        img {
            max-width: 100%;
            display: block;
        }
    }
}

.single-employee-content {
    width: 100%;
    max-width: 1140px;
    padding: 0 20px;
    margin: 40px auto 100px auto;

    @include below(500px) {
        margin: 40px auto;
    }

    &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 60px;
        align-items: flex-start;

        @include below(500px) {
            margin-bottom: 10px;
        }
    }

    &__title {
        font-size: 58px;
        line-height: 58px;
        font-weight: 400;

        @include below(500px) {
            font-size: 32px;
            line-height: 42px;
        }
    }

    &__text {
        width: 100%;
        max-width: 634px;
        font-size: 16px;
        line-height: 29px;
        font-weight: 400;

        p {
            margin: 20px 0;
        }

        ul, ol {
            padding: 0 20px;

            li {
                line-height: 20px;
                margin-bottom: 10px;
            }
        }
    }
}

.v-card-link {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    padding: 15px;
    display: block;
    width: 100%;
    max-width: 266px;
    color: #000;
    border: 1px solid #E2E2E2;
    text-decoration: none;
    text-align: center;
    border-radius: 5px;
    transition: background .2s linear, color .2s linear;

    @include below(620px) {
        display: none;
    }

    @include below(420px) {
        max-width: none;
    }

    &.mobile {
        display: none;

        @include below(620px) {
            display: block;
            margin-top: 40px;
        }
    }

    &:hover {
        background: #000;
        color: #fff;
    }
}
