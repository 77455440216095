.site-navigation {
    top: 0;
    left: 0;
    z-index: 999;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 50px 50px 0 50px;
    height: 95px;

    @include below(960px) {
        padding: 0 20px;
        height: 100px;
        align-items: center;
    }

    &__logo {
        width: 15%;
        display: block;

        @include below(960px) {
            width: 50%;
        }

        svg {
            width: 200px;
            height: 45px;

            @include below(600px) {
                width: 180px;
                height: 45px;
            }
        }
    }

    &__nav {
        width: 65%;
        text-align: center;

        .menu {
            .menu-item {
                list-style: none;
                display: inline-block;
                margin: 0 15px;

                a {
                    color: #fff;
                    text-decoration: none;
                    font-size: 16px;
                    line-height: 21px;
                    font-weight: 400;
                    position: relative;

                    &:hover {
                        &:after {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }

                    &:after {
                        content: "";
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        background: #fff;
                        opacity: 0;
                        bottom: -2px;
                        left: 0;
                        transform: translateY(4px);
                        transition: opacity .2s ease-in-out, transform .2s ease-in-out;
                    }
                }
            }
        }

        @include below(960px) {
            display: none;
        }
    }

    &__lang-switch {
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        margin: 0 15px 0 0;
        position: relative;

        &:hover {
            &:after {
                opacity: 1;
                transform: translateY(0);
            }
        }

        &:after {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            background: #fff;
            opacity: 0;
            bottom: -2px;
            left: 0;
            transform: translateY(4px);
            transition: opacity .2s ease-in-out, transform .2s ease-in-out;
        }

    }

    &__button {
        width: 20%;
        color: #fff;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        text-align: right;
        min-width: 210px;

        &-text {
            margin-right: 10px;
        }

        @include below(960px) {
            width: 50%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-end;
            min-width: none;

            &-text {
                display: none;
            }
        }
    }
}
