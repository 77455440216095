.contact-page {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 100px 20px;

    @include below(620px) {
        flex-direction: column;
        padding: 45px 20px;
    }

    &__column {
        width: 50%;
        max-width: 450px;

        @include below(620px) {
            width: 100%;
            max-width: none;
        }

        img {
            max-width: 100%;
            display: block;
        }
    }

    &__contact {
        padding: 0 30px 0 0;

        @include below(620px) {
            padding: 0;
        }

        li {
            list-style: none;
            margin-bottom: 40px;
            font-size: 24px;
            line-height: 24px;
            color: #000;

            @include below(400px) {
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 25px;
            }

            a {
                color: #000;
                text-decoration: none;

                &.contact-page__map-link {
                    display: block;
                    margin-top: 8px;
                    text-decoration: underline;
                }
            }
        }

        &-title {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            display: block;
            margin-bottom: 10px;
        }
    }

    &__reception-hours {
        text-align: right;
        margin: 35px 0 0 0;
        font-size: 16px;
        line-height: 22px;

        @include below(620px) {
            text-align: left;
            margin: 25px 0 0 0;
        }

        h4 {
            font-weight: 500;
        }

        p {
            font-weight: 400;
        }
    }
}
