.text-block {
    width: 100%;
    max-width: 636px;

    &__title {
        font-size: 24px;
        line-height: 29px;
        font-weight: 500;
        margin: 0 0 40px 0;
    }

    &__text {
        h2, h3 {
            font-weight: 500;
        }
        
        p {
            margin: 20px 0;

            a {
                color: $blackish;
                text-decoration: underline;
            }
        }

        ul, ol {
            padding: 20px;

            li {
                font-size: 16px;
                margin-bottom: 5px;
            }
        }

        a {
            color: $blackish;
        }
    }

    &__small-title {
        font-size: 16px;
        line-height: 29px;
        font-weight: 500;
        margin: 20px 0 10px 0;
    }

    &__contact {
        font-size: 16px;
        line-height: 29px;
        font-weight: 400;
        display: block;
        text-decoration: none;
        color: #000;

        &:hover {
            text-decoration: underline;
        }
    }
}
